[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

/**
 * CUT HERE
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

@import '../cru-scss/cru';

/**
 * END CUT HERE
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

// Give Site Front End
@import 'node_modules/slick-carousel/slick/slick';
@import 'slickstyle';
@import 'tabs';
@import 'give';
@import 'form';
@import 'cart';
@import 'checkout';
@import 'gift-config';
@import 'modal';
@import 'account_management';
@import 'slider';
@import 'homepage';
@import 'search';
@import 'mobile';
@import 'nav-cart';
@import 'carousel';
@import 'directives/creditCardCvv';

// TODO: Remove this manual import when we figure out why styles imported in components are not being added to the page
@import '../../app/designationEditor/designationEditor';
